<template>

  <div style="min-height: 390px;margin-top: 180px;background: #f3f6f7">
    <div style="display:none">
      <!--      <img src="@/assets/img/shop-banner.png" alt="" style="margin-top: -30px;width: 100%;height: 420px;">-->
      <el-carousel  trigger="click" style="margin-top: -30px;width: 100%;height:  420px;">
        <el-carousel-item  :key="index" v-for="(item, index) in bannerList.slice().reverse()" style="width: 100%;height:420px;">
          <img :src="item.value"   @click="goLink(item.label)" alt="" style="width: 100%;height:  420px;">

        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="w neck-img" >
      <div> <router-link to="/goods/stock-list" ><img  src="@/assets/img/shop-left .png" alt=""></router-link>  </div>
      <div> <router-link to="/goods/presale-list" ><img src="@/assets/img/shop-right.png" alt=""></router-link>  </div>
    </div>
    <div class="w recommend-box" >
      <div class="boutique">
        <div><h3>精品推荐</h3><span>精品速递 好货快享</span> </div>
        <shopBanner></shopBanner>
      </div>
      <div class="brand">
        <div style="display: flex;justify-content: space-between">
          <div><h3>品牌推荐</h3><span>正品保证</span></div>
          <div class="more-sty"> <router-link to="/shop/list" >   更多 <span> &gt; </span></router-link> </div>
        </div>
        <div style="display: flex;flex-wrap: wrap;margin-top: 55px">
        <div v-for="(item,i) in brandList" :key="i" class="brandList">
          <router-link :to="`/shop/detail/${item.smemberid}`" >
          <img :src="item.slogo" alt="" style="width:40px;height: 40px">
          <div>{{item.sshopname}}</div>
          </router-link>
        </div>
        </div>
      </div>
      <div class="ranking">
        <div style="display: flex;justify-content: space-between">
          <div><h3>排行榜</h3><span>热销榜单推荐</span></div>
<!--          <div class="more-sty">更多 <span> &gt; </span></div>-->
        </div>
        <div class="swiper-box">
          <div class="swiper">
            <div class="swiper-wrapper" style="display: flex">
              <div class="swiper-slide" v-for="(goodsList,categoryName) in rankingList"  style="width: auto !important;margin-right: 10px !important;">
                <div class="ranking-but">
                  <el-radio-group v-model="tabPosition" style="margin: 30px 0 ;"  :key="categoryName">
                    <el-radio-button :label="categoryName">{{categoryName}}</el-radio-button>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template style="display: flex;">
          <div v-if="index<3" v-for="(goods,index) in rankingList[tabPosition]" :key="goods.sguid" class="rankingList-item">
            <img :src="require(`@/assets/img/ranking-${index+1}.png`)" alt="" style="width: 30px;height: 40px;margin: 0 10px 5px 0">
            <router-link :to="`/goods/detail?goodsId=${goods.sguid}`"> <img :src="goods.img" alt="" style="width: 53px;height: 53px;"/></router-link>
            <span >
              {{goods.sgoodstitle}}
            </span>
          </div>

        </template>

      </div>
    </div>
    <!-- 搜索 -->
    <div class="w list-search" >
     <div style="margin: 20px auto;text-align: center">
       <img src="@/assets/img/products-icon.png" alt="" width="92px" height="42px" style="vertical-align:middle">
       <h1 style="display: inline-block;margin: 15px 30px">新品上架</h1>
       <img src="@/assets/img/products-icon.png" alt="" width="92px" height="42px" style="vertical-align:middle;transform: rotateY(180deg)">
     </div>
      <!-- 搜索属性 -->
<!--      <div class="search-key m-t-10" :class="!isShow?'showmore':''" v-if="searchAttrs.length > 0 || form.attrsearchPoList.length > 0 || showCategoryList.length > 0">-->
<!--        <el-row v-if="showCategoryList.length > 0">-->
<!--          <el-col :span="24" class="f-a-i f13">-->
<!--            <label>分类：</label>-->
<!--            <div v-for="item in showCategoryList">-->
<!--              <el-button style="margin:auto 10px" type="text" @click="handleSearchCategory(item.value)">{{item.label}}</el-button>-->
<!--            </div>-->
<!--          </el-col>-->
<!--        </el-row>-->

<!--        <div class="searchKeySelected" v-if="form.attrsearchPoList.length > 0">-->
<!--          <label>已选条件：</label>-->
<!--          <el-tag-->
<!--            :key="attr.skey"-->
<!--            @close="handleDeleteSearchKey(attr)"-->
<!--            closable-->
<!--            v-for="attr in form.attrsearchPoList"-->
<!--          >{{attr.parentLabel + ':' + attr.childLabel}}</el-tag>-->
<!--        </div>-->
<!--        <template v-for="(attr,index) in searchAttrs" >-->
<!--          <div :key="index" class="key-box" v-if="attr.visible">-->
<!--            <label>{{attr.label}}：</label>-->
<!--            <div class="key-value" :class="openList.indexOf(index) != -1?'open':''">-->
<!--              <template v-for="item in attr.children">-->
<!--                <a-->
<!--                  :key="item.value"-->
<!--                  @click="handleAddSearchKey(attr.label, attr.key, item.label, item.value)"-->
<!--                  class="f13"-->
<!--                  href="javascript:;"-->
<!--                >{{item.label}}</a>-->
<!--              </template>-->
<!--            </div>-->
<!--                <el-button type="text" style="margin-top: 5px;" v-if="openList.indexOf(index) == -1 && attr.children.length >= 10" @click="opens(index)">展开</el-button>-->
<!--                <el-button type="text" style="margin-top: 5px;" v-if="openList.indexOf(index) != -1" @click="closes(index)">收起</el-button>-->
<!--                <div v-if="attr.label == '规格'" class="search-t1" style="margin-top: 10px;">-->
<!--                  <el-input v-model="SpecificationData" type="text"-->
<!--                  size="mini" style="width: 150px;" label-width="80px" placeholder="请输入内容"></el-input>-->
<!--                  <el-button size="mini" style="width: 55px; height: 27px;" type="primary" @click="hunt()">搜索</el-button>-->
<!--                </div>-->
<!--                <div v-if="attr.label == '地区'" class="search-t1" style="margin-top: 10px;">-->
<!--                  <el-input v-model="form.interested" size="mini" type="text"-->
<!--                  label-width="80px" placeholder="请输入内容"></el-input>-->
<!--                  <el-button size="mini" style="width: 55px; height: 27px;" type="primary" @click="region()">搜索</el-button>-->
<!--                </div>-->
<!--          </div>-->
<!--        </template>-->
<!--      </div>-->
<!--      <div class="more" >-->
<!--        <el-button type="text" v-if="isShow && searchAttrs.length != 0" @click="more()">展开筛选</el-button>-->
<!--        <el-button type="text" v-if="!isShow && showCategoryList.length != 0" @click="more()">收起筛选</el-button>-->
<!--      </div>-->
      <!-- 搜索条件和内容 -->
      <div class="search-content">
        <div class="f">
          <el-row class="f left-box">
            <div>
              <el-button-group class="clearfix">
                <template v-for="v in sortList">
                  <el-button
                    :icon="v.status === 0 ? '' : v.status === 1 ? 'el-icon-top' : 'el-icon-bottom'"
                    :key="v.value"
                    :type="v.checked ? 'primary' : ''"
                    @click="handleSortChange(v)"
                    class="f13"
                    size="small"
                  >{{v.label}}</el-button>
                </template>
              </el-button-group>
            </div>
            <div class="m-l-40 f13">
              <!-- 仓单类型： -->
                <!-- <el-select v-model="form" placeholder="请选择仓单类型">
                  <el-option
                    v-for="item in warehouseList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select> -->
            </div>
            <div class="m-l-40 f13">
              价格：
              <el-input class="f13" placeholder="¥" style="width:90px" v-model="form.minfprice"></el-input>-
              <el-input class="f13" placeholder="¥" style="width:90px" v-model="form.maxfprice"></el-input>
              <el-button @click="priceSearch" class="m-l-20 f13" size="small" type="primary">确定</el-button>
              <el-button @click="handleReset" class="f13" size="small" type="primary">重置</el-button>
            </div>
          </el-row>
          <el-row>
            <el-radio-group class="clearfix" v-model="arrangeType">
              <el-radio-button label="vertical" size="small">
                <i class="el-icon-s-grid"></i>
              </el-radio-button>
              <el-radio-button label="horizontal" size="small">
                <i class="el-icon-s-unfold"></i>
              </el-radio-button>
            </el-radio-group>
          </el-row>
        </div>
      </div>
    </div>
    <!-- 产品 start -->
    <div class="w pro-list clearfix">
      <!-- 列表信息 -->
<!--      <goods-listcom v-if="goodsList.length>0" ref="goodslistRef" :arrangeType="arrangeType" :goodsList="goodsList" @toDetail="toDetail" @compareGoods="compareGoods"></goods-listcom>-->
      <goods-listcom v-if="goodsList.length>0" ref="goodslistRef" :arrangeType="arrangeType" :goodsList="goodsList" @toDetail="toDetail"></goods-listcom>
      <div class="center f-cb p-t-80" v-else>
        <i class="f60 iconfont icon-meiyoushuju"></i>
        <p class="p-t-20">暂无商品信息</p>
      </div>
    </div>
    <div class="list-pagination">
      <el-pagination
        :current-page="form.pageNum"
        :page-size="form.pageSize"
        :page-sizes="[20, 30, 40, 50]"
        :total="total"
        @current-change="v => {this.form.pageNum = v; this.loadGoodsList();this.scrollTop()}"
        @size-change="v => {this.form.pageSize = v; this.loadGoodsList();}"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
    <!-- 产品 end -->
  </div>
</template>

<script>
import goodsListcom from '@/components/business/goods-list'
import shopBanner from '@/components/business/shopBannre'
import goodsApi from '../../api/goodsApi'
import categoryApi from '../../api/categoryApi'
import commodityApi from '../../api/commodityApi'
import memberShopApi from "@/api/memberShopApi";
import Swiper from "swiper";

export default {
  components: {
    'goods-listcom': goodsListcom,
    shopBanner
  },
  data () {
    return {
      mySwiper: undefined,
      tabPosition:'采掘设备',
      rankingList:[],

      brandList: [],
      SpecificationData: '',
      Specification: [],
      warehouseList: [
        { lable: '现货仓单', value: 0},
        { lable: '电子仓单', value: 1}
      ],
      drawer: false,
      openList: [],
      openIndex: 99,
      ChangeHeight: 'ChangeHeight',
      isShow: true,
      goodsList: [],
      selectedCategoryIds: [],
      searchAttrs: [],
      sortList: [
        { label: '默认', status: 0, checked: false, value: 2 },
        { label: '销量', status: 0, checked: false, value: 0 },
        { label: '价格', status: 0, checked: false, value: 5 }
      ],
      form: {
        /**
         * 分类编码
         */
        igoodstype:0,
        scategoryid: '',
        scommodityid: '',
        scommodityname: '',
        /**
         * 搜索关键词
         */
        interested: '',
        attrsearchPoList: [],
        minfprice: '',
        maxfprice: '',
        /**
         * 按热力值排序
         */
        orderColumn: 2,
        orderType: 2,
        pageNum: 1,
        pageSize: 20
      },
      total: 0,

      goodCatOptions: [],
      showCategoryList: [],
      navCategoryList: [],

      salesOptions: [
        { label: '请选择', value: '0' },
        { label: '销量由低到高', value: '1' },
        { label: '销量由低到高', value: '2' }
      ],
      bannerList: [
        {
          label: '',
          value: require('@/assets/img/shop-banner.png')
        }
      ],

      commodityList: [],
      // compareGoodsArr: [],
      compareids: [],
      sortType: '1', // 排序方式
      arrangeType: 'vertical' // 商品排列方式 ,horizontal,vertical
    }
  },
  watch: {
    /**
     * 监控路由地址变化，处理商品搜索
     */
    $route (to) {
      this.handleUrlParams()
    }
  },
  created () {
    this.form.interested = this.$router.currentRoute.query.searchKey || ''
    this.loadGoodsCategoryList()
    this.loadGoodsList()
    this.loadShop()
    this.loadRankings()
    // this.$bus.$on('showCompare', this.showCompare)
    // if (localStorage.getItem('COMPARE_GOODS')) {
    //   this.compareids = JSON.parse(localStorage.getItem('COMPARE_GOODS')).concat()
    // }
    const bannerLists = this.$dictList('GOOD_INDEX_BANNER')
    bannerLists.forEach(res => {
      res.value = res.value.toLowerCase()

    })
    this.bannerList = bannerLists
  },
  methods: {
    async loadRankings() {
      const res = await goodsApi.getHots({pageSize: 100});
      let list = res.list
      let map={}
      list.forEach( x=>{
        const json = JSON.parse(x.sjson);
        if (json.imgs && json.imgs.length > 0) {
          x.img = json.imgs[0].url;
        }
        let goodsList = map[x.scategory];
        if (goodsList) {
          goodsList.push(x)
        }else{
          map[x.scategory] = [x]
        }

      })
      this.tabPosition=Object.keys(map)[0]
      this.rankingList = map;
      this.$nextTick(() => {

          this.mySwiper = new Swiper('.swiper', {
            slidesPerView: 3,
            spaceBetween: 30
          })
          // setInterval(() => {
          //   this.mySwiper.slideNext(1000)
          // }, 3000)

      })

    },
    async loadShop() {
      const res = await memberShopApi.page({pageSize: 4});
      this.brandList = res.list
    },
    region () {
      const nameReg = /[^\u4e00-\u9fa5]/g
      if (nameReg.test(this.form.interested)) {
        this.form.interested = ''
        return this.$message.error('请输入文字')
      }
      if (this.form.interested !== '') {
        const parentLabel = '地区'
        const childLabel = this.form.interested
        const skey = '地区'
        const svalue = this.form.interested
        this.handleAddSearchKey(parentLabel, skey, childLabel, svalue);
        (this.searchAttrs.find(v => v.key === '地区') || {}).visible = false
        const i2 = this.form.attrsearchPoList.findIndex(v => v.skey === '地区')
        if (i2 > -1) {
          this.form.interested = this.form.attrsearchPoList[i2].svalue
        }
        this.loadGoodsList()
      } else {
        return
      }
    },
    hunt () {
      const rel = /^[0-9a-zA-Z]+$/
      if (!rel.test(this.SpecificationData)) {
        this.SpecificationData = ''
        return this.$message.error('请输入数字或英文字母')
      }
      if (this.SpecificationData !== '') {
        this.Specification = {
          parentLabel: '规格',
          childLabel: this.SpecificationData,
          skey: '规格',
          svalue: this.SpecificationData
        }
        this.form.attrsearchPoList.push(this.Specification);
        (this.searchAttrs.find(v => v.key === '规格') || {}).visible = false
        const i = this.form.attrsearchPoList.findIndex(v => v.skey === 'scommodityid')
        if (i > -1) {
          this.form.scommodityid = this.form.attrsearchPoList[i].svalue
        }
        // const i2 = this.form.attrsearchPoList.findIndex(v => v.skey === '地区')
        // if (i2 > -1) {
        //   this.form.interested = this.form.attrsearchPoList[i2].svalue
        // }
        this.loadGoodsList()
      } else {
        return
      }
    },
    scrollTop () {
      document.documentElement.scrollTop = 0
      window.pageYOffset = 0
      document.body.scrollTop = 0
    },
    // showCompare (status) {
    //   this.drawer = status
    // },
    // cleanGoods () {
    //   for (const i in this.compareGoodsArr) {
    //     this.$refs.goodslistRef.deleteTags(this.compareGoodsArr[i])
    //   }
    //   this.compareGoodsArr = []
    // },
    // goGoodsCompare () {
    //   this.$router.push('/goods/compare')
    // },
    // closeTag (tag, index) {
    //   this.compareGoodsArr.splice(index, 1)
    //   this.$refs.goodslistRef.deleteTags(tag)
    // },
    // compareGoods (good) {
    //   if (this.compareGoodsArr.length < 5) {
    //     // var tempgood = this.compareGoodsArr.find((item) => item.sguid === good.sguid)
    //     // if (tempgood) {
    //     //   this.$message.error('此商品已包含在对别列表，请不要重复添加！')
    //     // } else {
    //     this.compareGoodsArr.push(good)
    //     // }
    //   }
    // },
    more () {
      this.isShow = !this.isShow
    },
    opens (index) {
      this.openList.push(index)
      this.openIndex = index
    },
    closes (index) {
      this.openList.splice(this.openList.indexOf(index), 1)
      // console.log(index)
    },
    toDetail (item) {
      this.$router.push(`/goods/detail?goodsId=${item.sguid}`)
    },
    /**
     * 处理网址参数
     */
    handleUrlParams () {
      this.showCategoryList = []
      this.navCategoryList = []

      // 处理关键词搜索
      if (!this.$utils.isEmpty(this.$route.query.searchKey)) {
        this.form.interested = this.$route.query.searchKey
        this.form.attrsearchPoList = [ {
          parentLabel: '关键词',
          childLabel: this.$route.query.searchKey,
          skey: 'searchKey',
          svalue: this.$route.query.searchKey
        } ]
      }
      // 处理分类加载
      const categoryId = this.$route.query.categoryId
      if (!this.$utils.isEmpty(categoryId)) {
        // 处理导航分类信息
        const findParents = (id) => {
          const o = this.deepFind(this.goodCatOptions, id)
          if (undefined === o || o === null) {
            return
          }
          this.navCategoryList.unshift({
            label: o.name,
            value: o.code
          })
          if (this.$utils.isEmpty(o.parentCode)) {
            return
          }
          findParents(o.parentCode)
        }
        findParents(categoryId)
        console.debug('分类信息为:', JSON.stringify(this.navCategoryList))
        // 处理展示分类信息
        const o = this.deepFind(this.goodCatOptions, categoryId)
        if (o !== undefined && o.children && o.children.length > 0) {
          this.showCategoryList = o.children.map(v => {
            return { label: v.name, value: v.code }
          })
        }
        this.form.scategoryid = categoryId
      } else {
        this.showCategoryList = this.goodCatOptions.map(v => {
          return { label: v.name, value: v.code }
        })
      }

      // console.debug('本次查询到的子级信息为:', JSON.stringify(this.showCategoryList));
      // this.handleGoodsCategoryChange(this.navCategoryList.map(v => v.value));

      const node = this.navCategoryList.map(v => v.value)
      this.searchAttrs = this.searchAttrs.filter(v => v.key === 'searchKey')
      this.form.attrsearchPoList = this.form.attrsearchPoList.filter(v => v.skey === 'searchKey')
      // this.form.scategoryid = '';
      console.debug('本次选中的分类信息为:', node)
      if (node.length > 0) {
        const categoryId = node[node.length - 1];

        (async () => {
          const list = await commodityApi.list({
            scategoryid: categoryId
          })
          if (list.length > 0) {
            let cl = ''
            this.searchAttrs.push({
              label: '品名',
              key: 'scommodityid',
              children: list.map(v => {
                if (!this.$utils.isEmpty(this.$route.query.commodityId) && v.sguid === this.$route.query.commodityId) {
                  cl = v.sname
                }
                return {
                  label: v.sname,
                  value: v.sguid
                }
              }),
              visible: true
            })
            if (!this.$utils.isEmpty(cl)) {
              this.handleAddSearchKey('品名', 'scommodityid', cl, this.$route.query.commodityId)
            }
          }

          // 查找选中的末节分类信息
          const category = this.deepFind(this.goodCatOptions, categoryId)
          console.debug('找到的分类信息为:', JSON.stringify(category))
          if (category !== undefined) {
            this.loadAttrs(category)
          }
          this.form.scategoryid = categoryId
        })()
      }
      if (this.$utils.isEmpty(this.$route.query.commodityId)) {
        this.loadGoodsList()
      }
    },
    /**
     * 加载商品分类信息
     */
    async loadGoodsCategoryList () {
      this.goodCatOptions = await categoryApi.tree({})
      this.handleUrlParams()
    },
    /**
     * 处理分类改变时
     */
    async handleGoodsCategoryChange (node) {
      this.searchAttrs = this.searchAttrs.filter(v => v.key === 'searchKey')
      this.form.attrsearchPoList = this.form.attrsearchPoList.filter(v => v.skey === 'searchKey')
      this.form.scategoryid = ''
      console.debug('本次选中的分类信息为:', node)
      if (node.length > 0) {
        const categoryId = node[node.length - 1]

        const list = await commodityApi.list({
          scategoryid: categoryId
        })
        if (list.length > 0) {
          this.searchAttrs.push({
            label: '品名',
            key: 'scommodityid',
            children: list.map(v => {
              return {
                label: v.sname,
                value: v.sguid
              }
            }),
            visible: true
          })
        }
        // 查找选中的末节分类信息
        const category = this.deepFind(this.goodCatOptions, categoryId)
        console.debug('找到的分类信息为:', JSON.stringify(category))
        if (category !== undefined) {
          this.loadAttrs(category)
        }
        this.form.scategoryid = categoryId
      }

      this.loadGoodsList()
    },
    /**
     * 加载分类的动态属性
     */
    loadAttrs (category) {
      const sjson = category.resource.sjson
      if (sjson === null || sjson === '' || sjson === '{}') {
        return
      }
      console.debug('本次查询到的动态属性信息为:', sjson)
      const attrs = JSON.parse(sjson || '[]')
      attrs.filter(v => v.search).forEach(v => {
        if (this.$utils.isEmpty(v.defValues) && this.$utils.isEmpty(v.dictType)) {
          console.warn('动态信息:', JSON.stringify(v), '存在问题...')
          return
        }
        let usedict = false
        if (Reflect.has(v, 'dictType') && !this.$utils.isEmpty(v.dictType)) {
          usedict = true
        }
        let defValues = v.defValues
        if (usedict) {
          defValues = this.$dictList(v.dictType)
        }
        this.searchAttrs.push({
          label: v.field,
          key: v.field,
          children: usedict ? defValues : { label: defValues, value: defValues },
          visible: true
        })
      })
      // console.info(this.searchAttrs)

      if (!this.$utils.isEmpty(this.$route.query.pl) &&
          !this.$utils.isEmpty(this.$route.query.k) &&
          !this.$utils.isEmpty(this.$route.query.cl) &&
          !this.$utils.isEmpty(this.$route.query.v)) {
        this.handleAddSearchKey(this.$route.query.pl, this.$route.query.k, this.$route.query.cl, this.$route.query.v)
      }
    },
    handleSearchCategory (code) {
      let url = `/goods/list?categoryId=${code}`
      if (!this.$utils.isEmpty(this.$route.query.searchKey)) {
        url += `&searchKey=${this.$route.query.searchKey}`
      }
      this.$router.push(url)
    },
    /**
     * 添加搜索属性
     */
    handleAddSearchKey (pl, k, cl, v) {
      console.info('本次添加的参数为:', pl, k, cl, v)
      this.form.attrsearchPoList.push({
        parentLabel: pl,
        childLabel: cl,
        skey: k,
        svalue: v
      });
      console.dir(this.form.attrsearchPoList);
      (this.searchAttrs.find(v => v.key === k) || {}).visible = false
      const i = this.form.attrsearchPoList.findIndex(v => v.skey === 'scommodityid')
      if (i > -1) {
        this.form.scommodityid = this.form.attrsearchPoList[i].svalue
      }
      const i2 = this.form.attrsearchPoList.findIndex(v => v.skey === '地区')
      if (i2 > -1) {
        this.form.interested = this.form.attrsearchPoList[i2].svalue
      }
      this.loadGoodsList()
    },
    /**
     * 删除搜索属性
     */
    handleDeleteSearchKey ({ skey }) {
      const index = this.form.attrsearchPoList.findIndex(v => v.skey === skey)
      if (index === -1) {
        return
      }
      this.form.attrsearchPoList.splice(index, 1);
      (this.searchAttrs.find(v => v.key === skey) || {}).visible = true
      if (skey === 'scommodityid') {
        this.form.scommodityid = ''
      }
      if (skey === '品名') {
        this.form.interested = ''
      }
      if (skey === 'searchKey') {
        this.form.interested = ''
      }
      this.form.interested = ''
      this.SpecificationData = ''
      this.loadGoodsList()
    },
    /**
     * 加载商品列表
     */
    loadGoodsList () {
      const { attrsearchPoList, ...param } = this.form
      // 品名属性不加入请求参数attrsearchPoList列表中
      param.attrsearchPoList = []
      if (this.form.attrsearchPoList.length) {
        this.form.attrsearchPoList.forEach(v => {
          if (v.skey !== 'scommodityid' && v.skey !== 'searchKey' && v.skey !== '地区') {
            param.attrsearchPoList.push(v)
          }
        })
      }
      // 将分类编号替换成分类的编码，来查询对应分类及以下子集的所有商品
      if (!this.$utils.isEmpty(param.scategoryid)) {
        const category = this.deepFind(this.goodCatOptions, param.scategoryid)
        if (category !== null) {
          param.scategorycode = category.resource.ssystemcode
          Reflect.deleteProperty(param, 'scategoryid')
        }
      }

      goodsApi.page({
        ...param
      }).then(data => {
        this.form.pageNum = data.pageNum
        this.form.pageSize = data.pageSize
        this.total = data.total
        // 处理商品图片
        data.list.forEach(g => {
          if (!this.$utils.isEmpty(g.sjson)) {
            const json = JSON.parse(g.sjson)
            if (json && json.imgs && json.imgs.length > 0) {
              g.img = json.imgs[0].url || json.imgs[0]
            }
          }
        })
        this.goodsList = data.list
      }).catch(error => {
        console.error('未查询商品列表信息:', error)
      })
    },
    /**
     * 重置搜索条件
     */
    handleReset () {
      // this.form.scategoryid = ''
      this.selectedCategoryIds = []
      this.form.scategoryid = this.$tools.urlParam('categoryId') || ''
      this.form.scommodityname = this.$router.currentRoute.query.searchKey || ''
      this.form.scommodityid = ''
      this.form.interested = ''
      this.form.maxfprice = ''
      this.form.minfprice = ''
      this.form.orderColumn = 2
      this.form.orderType = 0

      // 清除已经选择的属性信息
      this.form.attrsearchPoList.forEach(v => {
        this.handleDeleteSearchKey(v)
      })
      this.$route.query.searchKey = ''// 清空关键词搜索
      this.sortList = [
        { label: '默认', status: 0, checked: false, value: 2 },
        { label: '数量', status: 0, checked: false, value: 4 },
        { label: '价格', status: 0, checked: false, value: 5 }
      ]

      this.loadGoodsList()
    },

    // 顶部新加搜索
    handleSortChange (v) {
      console.debug('本次操作的数据为:', JSON.stringify(v))
      if (v.checked) {
        v.status += 1
        if (v.status > 2) {
          v.status = 1
        }
      } else {
        const others = this.sortList.find(o => o.checked)
        if (others !== undefined) {
          others.checked = false
          others.status = 0
        }
        v.checked = true
        v.status = 1
      }
      this.form.orderColumn = v.value
      this.form.orderType = v.status
      console.debug('操作后的数据为:', JSON.stringify(v))
      this.loadGoodsList()
    },
    priceSearch () {
      this.form.minfprice = this.form.minfprice.trim()
      if (!this.$utils.isEmpty(this.form.minfprice)) {
        if (!/^\d+(\.\d+)?$/.test(this.form.minfprice)) {
          this.$message({
            type: 'warning',
            message: '请输入正确的最小价格'
          })
          return
        }
      }
      this.form.maxfprice = this.form.maxfprice.trim()
      if (!this.$utils.isEmpty(this.form.maxfprice)) {
        if (!/^\d+(\.\d+)?$/.test(this.form.maxfprice)) {
          this.$message({
            type: 'warning',
            message: '请输入正确的最大价格'
          })
          return
        }
      }

      this.loadGoodsList()
    },
    deepFind (list, code) {
      let o = list.find(v => v.code === code)
      if (o !== undefined) {
        return o
      }
      for (let i = 0; i < list.length; i++) {
        if (list[i].children == null || list[i].children.length < 1) {
          continue
        }
        o = this.deepFind(list[i].children, code)
        if (o !== undefined) {
          break
        }
      }
      return o
    }
  }
}
</script>
<style lang="scss">
.more{
  text-align: center;
  height: 39px;
  line-height: 40px;
}
.list-search {
  // height: 125px;
  // overflow: hidden;
  // border-bottom: solid 1px #e7e7e7;
  /*margin-top: 10px;*/

  .search-key {
    max-height: 300px;
    // height: 48px;
    overflow: hidden;
    border: solid 1px #e7e7e7;
    background: #fff;
    transition: max-height .3s ease-in;
    transform-origin: 50% 0;
    animation: slide-down 0.3s ease-in;
      &.showmore{
      max-height: 515px;
    }
    .key-box {
      /*margin: 10px auto 10px;*/
      display: -webkit-box;
      border-bottom: 1px solid #f6f6f6;
        // display: flex;
        // align-items: center;
      .key-value {
        width: 70%;
        height: 49px;
        overflow: hidden;
        &.open{
          height: auto;
        }
      }
      .search-t1 {
        display: flex;
        // align-items: center;
        width: 159px;
        margin-left: 5px;
      }
      .multiple-choice {
        width: 10px;
        height: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 12px;
      }
      // .line{
      //   display:block;
      //   position:relative;
      //   height:49px;
      //   background-color:#fff;
      // }
    }
    label {
      width: 105px;
      font-size: 13px;
      background: #f6f6f6;
      padding: 15px 10px;
    }
    a {
      color: #ED2920;
      margin: auto 10px;
      min-width: 50px;
      display: inline-block;
      padding-top: 15px;
    }
    .el-tag {
      margin: auto 10px;
    }
  }
}
</style>

<style lang="scss">
.list-search .search-content {
  margin: 10px 0;
  border: solid 1px #e7e7e7;
  background: #fff;
  padding: 10px;
  .el-radio-button__inner {
    padding: 9px 20px;
  }
  .left-box {
    flex: 80%;
    justify-content: flex-start;
  }
}
.list-pagination {
  .el-pagination {
    margin: 0;
    padding: 40px 0;
  }
}
.neck-img{
  display: flex;
  justify-content: space-between;
  img{
    margin:20px 0;
    width: 580px;
    height: 240px;
  }

}
.recommend-box{
  display: flex;
  justify-content: space-between;
  >div{
    background: #fff;
    padding: 20px;
    height: 380px;
  }
  h3{
    font-size: 18px;
    display: inline-block;
    margin-right: 10px;
  }
  .boutique{
    width:490px;
  }
  .brand{
    width:385px ;
    .brandList{
      text-align: center;
      width: 50%;
      height: 130px;
      font-size: 12px;
      span{
        overflow:hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  .ranking{
    width:300px ;
    .ranking-but{
      display: inline-block;
      overflow: hidden;
      .el-radio-button:first-child:last-child .el-radio-button__inner{
        border-radius: 30px !important;
        margin-right: 10px;
        height: 30px !important;
        line-height: 5px !important;
      }
    }
  }
  .more-sty{
    font-size: 14px;
    line-height: 40px;
    span{
      font-size: 14px;
      padding: 0 4px;
      color: #CF3929;
      width: 30px;
      height: 30px;
      border: 1px solid #CF3929;
      border-radius: 30px;
    }
  }
}
.rankingList-item{
 position: relative;
  margin-bottom: 20px;
  span{
    position:absolute;
    left: 100px;
    top: 15px;
    width: 165px;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
  }
}
.swiper-box{
  display: flex;
  width: 280px;
  overflow: hidden;
  white-space: nowrap;
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    margin-right: 0 !important;
    width: auto !important;
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
}

</style>
