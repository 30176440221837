import { http } from 'security-view-deal'

/* 会员api */
export default {

  pageUrl: '/member/membershop/home/paged',
  page (data) {
    return http.get(this.pageUrl, data)
  },

}
