<template>
  <div class="shopBanner">
  <div class="home">
<!--    <div class="boxs" @mouseout="out" @mouseover="over">-->
<!--      <img-->
<!--        v-for="(item, index) in lists"-->
<!--        v-show="listIndex === index"-->
<!--        :key="index"-->
<!--        :src="item.img"-->
<!--        alt=""-->
<!--      />-->
<!--      <p class="left" @click="changePage(prevIndex)">&lt;</p>-->
<!--      <ul>-->
<!--        <li-->
<!--          :class="{ color: index == listIndex }"-->
<!--          v-for="(item, index) in lists"-->
<!--          @click="changePage(index)"-->
<!--          :key="index"-->
<!--        >{{item.tit}}</li>-->
<!--      </ul>-->
<!--      <p class="right" @click="changePage(nextIndex)">&gt;</p>-->
<!--    </div>-->

    <el-carousel :interval="4000" type="card" height="200px" style="position: relative" :initial-index="initialIndex" ref="carousel" @change="changeFun">
      <el-carousel-item v-for="(item, index) in list" :key="index" :name="item.img" >
        <router-link :to="`/goods/detail?goodsId=${item.sguid}`" > <img :src="item.img" alt="" style="width: 100%;height: 100%;" :key="index"/></router-link>
      </el-carousel-item>
    </el-carousel>
    <div style="text-align: center;margin-top: 20px;font-size: 16px">{{this.name}}</div>

  </div>

  </div>
</template>

<script>
import goodsApi from "@/api/goodsApi";

export default {
  components: {},
  props: {},
  data() {
    return {
      ppp:'',
      initialIndex:0,
      name:'',
      list:[],
      // lists: [
      //   { img:require("@/assets/img/shop-left .png"),tit:'精品速递 好货快享'},
      //   { img:require("@/assets/img/shop-right.png"),tit:'热销榜单推荐'}
      // ],
      listIndex: 0, //默认显示第几张图片
      timer: null, //定时器
    };
  },
  computed: {
    //上一张
    prevIndex() {
      if (this.listIndex == 0) {
        return this.list.length - 1;
      } else {
        return this.listIndex - 1;
      }
    },
    //下一张
    nextIndex() {
      if (this.listIndex == this.list.length - 1) {
        return 0;
      } else {
        return this.listIndex + 1;
      }
    },
  },
  methods: {
    changeFun(index){
      this.name=this.list[index].sgoodstitle
    },
    async loadData() {
      const res = await goodsApi.getHots({igoodstype:0 , pageSize: 5});
      console.log(res)
      this.list = res.list
      this.list.forEach( x=>{
        const json = JSON.parse(x.sjson);
        x.img = json.imgs[0].url;
        console.log(json,'////')
      })

    },
    changePage(index) {
      this.listIndex = index;
    },
    //移除
    out() {
      this.setTimer();
    },
    //移入
    over() {
      clearInterval(this.timer);
    },
    //1秒切图
    setTimer() {
      this.timer = setInterval(() => {
        this.listIndex++;
        if (this.listIndex == this.list.length) {
          this.listIndex = 0;
        }
      }, 1000);
    },
  },
  created() {

  },
  mounted() {
    this.loadData();
    console.log(this.list,'******')
  },
};
</script>
<style scoped lang="scss">
.shopBanner{
.home {
  width: 100%;
  height: 100%;
  margin: 35px auto;
  .boxs {
    position: relative;
    width: 385px;
    height: 240px;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
      z-index: 100;
    }
    p {
      cursor: pointer;
      color: white;
      font-size: 21px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 50px;
      background: rgba(0, 0, 0, 0.5);
    }
    .left {
      position: absolute;
      top:45%;
      left: 0;
    }
    .right {
      position: absolute;
      top: 45%;
      right: 0;
    }
    ul {
      list-style: none;
      display: flex;
      justify-content: space-around;
      align-items: center;
      position: absolute;
      width: 150px;
      height: 20px;
      bottom: -10%;
      right: 30%;
      .color {
        display: none;
        background: #fff;
        color: #fff;
      }
      li {
        text-align: center;
        font-size: 16px;
        cursor: pointer;
        width: 100%;
        height: 10px;
        border-radius: 50%;
      }
    }
  }
}
}
</style>
